.selected-day {
  background-color: #4caf50 !important;
  color: white !important;
}
@media (max-width: 1350px) {
  .flex-column {
    flex-direction: column !important;
  }
}
.flex-column {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0px 20px;
  width: 100%;
  margin-bottom: 20px;
}
