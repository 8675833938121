.loader {
  width: 3em; /* control the size*/
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
      /* linear-gradient(0deg ,rgb(231, 0, 42, .5) 30%,#0000 0 70%, rgb(231, 0, 42 /100%) 0) 50%/5% 100%,
      linear-gradient(90deg,rgb(231, 0, 42, .25) 30%,#0000 0 70%, rgb(231, 0, 42 /75% ) 0) 50%/100% 5% */ linear-gradient(
        0deg,
        rgb(231, 0, 42, 0.5) 30%,
        #0000 0 70%,
        rgb(231, 0, 42) 0
      )
      50%/5% 100%,
    linear-gradient(
        90deg,
        rgb(231, 0, 42, 0.25) 30%,
        #0000 0 70%,
        rgb(231, 0, 42, 0.75) 0
      )
      50%/100% 5%;
  background-repeat: no-repeat;
  animation: load 1s infinite steps(12);
}
.loader::before,
.loader::after {
  content: '';
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes load {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
