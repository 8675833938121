@tailwind base;
@tailwind components;
@tailwind utilities;

.input-box {
  @apply py-3.5 px-4 border w-full focus:outline-none placeholder-gray-light text-black-light rounded-20px border-gray-light focus:ring-1 focus:ring-gray-semiDark;
}
input[type='password']::placeholder {
  font-size: 16px;
  letter-spacing: normal;
  font-family: 'Nunito';
}
/* input[type="password"]::placeholder  {
  font-family: 'Nunito';
} */
span[contenteditable]:empty:focus::before,
span[contenteditable]:empty::before {
  content: 'Type something..';
  opacity: 0.4;
  color: black;
}

.textbox-highlight {
  @apply bg-transparent caret-primary-pinkishRed focus:outline-none focus:border-primary-pinkishRed border-gray-veryLight;
}
body::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #eee;
}
*::-webkit-scrollbar-horizontal {
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #e62e66 0%, #e7002c 100%) purple;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  opacity: 0.8;
}

.message-container {
  position: relative;
}

.options-menu {
  cursor: pointer;
}

.options-menu-opened {
  position: absolute;
  top: 30px; /* Adjust the desired vertical positioning */
  right: 0;
  border: 1px solid lightgrey; /* Add a border */
  background-color: #f4f4f4; /* Light grey background color */
  padding: 5px; /* Add some padding for better appearance */
  border-radius: 5px; /* Optional: Add border radius for rounded corners */
}